<template>
  <v-content>
    <v-container>
      <v-row align="center" justify="center">
        <v-col md="8" sm="12" lg="8" pa-2>
          <v-card>
            <v-card-text>
              <span class="title">Create a new customer</span>
              <v-divider></v-divider>
              <v-form ref="form" v-model="valid" autocomplete="false">
                <p class="mt-3 mb-4 subtitle-2">
                  Please enter the details of the customer:
                </p>
                <v-text-field
                  :rules="rules.email"
                  label="Email Id of customer"
                  v-model="email"
                  outlined
                ></v-text-field>
                <v-text-field
                  :rules="rules.name"
                  label="Name of customer"
                  v-model="name"
                  outlined
                ></v-text-field>
                <v-text-field
                  :rules="rules.mandatory"
                  label="Username"
                  v-model="username"
                  outlined
                ></v-text-field>
                <v-text-field
                  :rules="rules.password"
                  label="Password"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'"
                  @click:append="showPass = !showPass"
                  v-model="password"
                  outlined
                  autocomplete="new-password"
                ></v-text-field>
                <v-text-field
                  maxlength="10"
                  counter
                  :rules="rules.number"
                  label="Phone number"
                  type="text"
                  v-model="phone"
                  outlined
                ></v-text-field>
                <div class="headline pb-1">Products/Features Selection</div>
                <v-divider class="ml-5 mb-3"></v-divider>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="isVideoConference"
                      label="Enable Video Conference?"
                    ></v-checkbox>
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="disableRightClick"
                      label="Disable right click?"
                    ></v-checkbox>
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="isOtpSms"
                      label="Enable 2FA on SMS?"
                    ></v-checkbox>
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="firstTimeChangePassword"
                      label="First time password change needed?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="isVideoKYC"
                      label="Enable Video KYC?"
                    ></v-checkbox>
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="useStunTurn"
                      label="Enable stun turn?"
                    ></v-checkbox>
                    <v-checkbox
                      class="mt-0 mb-0"
                      v-model="isOtpEmail"
                      label="Enable 2FA on Email?"
                    ></v-checkbox>
                   <v-checkbox
                      class="mt-0 mb-0"
                      v-model="maxRecordRequired"
                      label="Do you want a max limit for recorded video in video conf?"
                    ></v-checkbox>
                    <v-text-field
                      v-if="maxRecordRequired"
                      label="Maximum recording time in minutes (for video conf)"
                      v-model="maxRecordingTime"
                      :rules="rules.timeCheck"
                      ref="variable_IdleTime"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn
                    color="primary"
                    :disabled="!isValid()"
                    @click="createCustomer()"
                    >Create customer</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { end_points } from "@/config";
export default {
  props: {
    source: String,
  },
  data: () => ({
    showPass:false,
    dialog: false,
    valid: false,
    isVideoConference: false,
    isVideoKYC: false,
    isOtpSms: false,
    isOtpEmail: false,
    isScreenshare: false,
    isRecording: false,
    useStunTurn: false,
    maxRecordRequired: false,
    maxRecordingTime: "",
    firstTimeChangePassword: false,
    disableRightClick: false,
    drawer: null,
    currentUserData: {},
    name: "",
    email: "",
    username: "",
    password: "",
    phone: "",
    rules: {
      mandatory: [(v) => /^.+$/.test(v) || "Field cannot be empty!"],
      name: [
        (v) => !!v || "Field cannot be empty!",
        (v) =>
          /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(v) ||
          "Not a valid name!",
      ],
      email: [
        (v) => !!v || "Field cannot be empty!",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Not a proper email!",
      ],
      timeCheck : [
        (value) =>
          !!value ||
          "Cannot be left empty!",
        (value) => 
         !/\D/.test(value) ||
          "Can take only numeric values",
        (value) => 
          !(parseInt(value) < 1 )||
          "Cannot be less than a minute"
      ],
      password: [
        v => !!v || "Field cannot be empty!",
        v =>  ((v.length >= 8) && /[a-z]/g.test(v) && /[A-Z]/g.test(v) && /[0-9]/g.test(v) && /[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(v)) 
          || "Password should be minimum 8 characters length including lowerCase, UpperCase, number and special character."
      ],
      number: [
        (v) => !!v || "Field cannot be empty!",
        (v) => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a valid mobile number!",
      ]
    },
  }),
  methods: {
    clearView() {
      this.username = "";
      this.password = "";
      this.email = "";
      this.name = "";
      this.maxRecordRequired = false;
      this.maxRecordingTime = "";
      this.isOtpSms = false;
      this.isOtpEmail = false;
      this.isScreenshare = false;
      this.isRecording = false;
      this.isVideoConference = false;
      this.isVideoKYC = false;
      this.useStunTurn = false;
      this.disableRightClick = false;
      this.firstTimeChangePassword = false;
      this.phone = '';
      this.$refs.form.reset();
    },
    isValid() {
      if (this.valid) {
        if (!this.isVideoKYC && !this.isVideoConference) return false;
        else return true;
      } else {
        return false;
      }
    },
    async createCustomer() {
      if (this.name && this.email && this.username && this.password) {
        try {
          let res = await axiosInstance.post(end_points.create_customer, {
            name: this.name,
            email: this.email,
            username: this.username,
            password: this.password,
            isVideoKYC: this.isVideoKYC,
            useStunTurn: this.useStunTurn,
            disableRightClick: this.disableRightClick,
            isVideoConference: this.isVideoConference,
            firstTimeChangePassword: this.firstTimeChangePassword,
            maxRecordRequired: this.maxRecordRequired || false,
            maxRecordingTime: this.maxRecordingTime || "",
            otpSms: this.isOtpSms,
            otpEmail: this.isOtpEmail,
            phone: this.phone,
            config: {
              introConf: {
                useStunTurn: this.useStunTurn || false,
                disableRightClick: this.disableRightClick || false,
                isVideoConference: this.isVideoConference || false,
                firstTimeChangePassword: this.firstTimeChangePassword || false,
              },
              global: {}
            },
          });
          console.log(res);
          if (res.data) {
            eventBus.$emit("vueSnack", "Successfully created a customer!");
            this.clearView();
          } else {
            eventBus.$emit(
              "vueSnack",
              "Creating customer failed!"
            );
          }
        } catch (error) {
          console.log(error);

          const errMsg= error?.response?.data?.error?.message
          
          eventBus.$emit("vueSnack", errMsg);
          // let errRes = error.response.data.error.details;
          // let errorToShow = [];
          // for (let i in errRes.messages) {
          //   errorToShow.push(errRes.messages[i]);
          // }
          // eventBus.$emit("vueSnack", errorToShow.join(", "));
        }
      } else {
        eventBus.$emit("vueSnack", "All the fields are requried!");
      }
    },
  },
  async created() {
    try {
      if (this.$store.getters.userData) {
        let { userId, token } = this.$store.getters.userData;
        axiosInstance.defaults.headers.common["Authorization"] = token;
        this.currentUserData = {
          ...this.$store.getters.userData,
        };
        this.$store.commit("setUserData", this.currentUserData);
      } else {
        this.$router.push("/super");
      }
    } catch (error) {
      console.log(error);
      this.$router.push("/super");
    }
  },
};
</script>
<style scoped>
.activeMenu {
  background: #d4d2d2;
  border-right: 4px solid #0184c4;
}
</style>