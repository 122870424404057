<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :mini-variant="mini"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            active-class="activeMenu"
            :class="item.path === $route.path ? 'activeMenu' : ''"
            @click="$router.push({path: item.path})"
          >
            <v-list-item-action>
              <v-icon :color="item.path === $route.path ? 'app_bar_icon' : ''">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <div class="text-center">
      <v-dialog
        v-model="logoutDialog"
        persistent
        width="35%"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Are you sure, you want to logout?
          </v-card-title>
          <br>
          <v-card-actions style="margin-top: -1rem; padding-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="logoutDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              color="error"
              @click="logout()"
            >
              Logout
              <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="app_bar_color" dark>
      <v-app-bar-nav-icon @click.stop="toogleSidebar()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <img height="30px;" src="@/assets/signzy.png" />
      </v-toolbar-title>
      <v-spacer />
      <v-btn depressed color="app_bar_color" @click="logoutDialog = true">
        Logout
        <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <router-view />
  </v-app>
</template>

<script>
import { sideBar, end_points } from "@/config";
export default {
  props: {
    source: String
  },
  data: () => ({
    dialog: false,
    drawer: null,
    mini: false,
    isSetting: false,
    items: sideBar.superAdmin,
    logoutDialog: false
  }),
  methods: {
    toogleSidebar() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.drawer = true;
        this.mini = !this.mini;
      } else {
        this.drawer = true;
        this.mini = false;
      }
    },
    logout() {
      axiosInstance.get(end_points.logout(sessionStorage.userId));
      eventBus.$emit("vueLoader",false);
      store.flush();
      this.$router.push({
        path: "/super"
      });
    }
  },
  created() {
    if (!this.$store.getters.userData || !sessionStorage.getItem('userId')) {
        this.$router.push("/super");
    }
  }
};
</script>
<style scoped>
.activeMenu {
  background: #d4d2d2;
  border-right: 4px solid #0184c4;
}
</style>